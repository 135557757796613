import '../styles/footer.css';

import React from "react";
import {useState} from 'react';
import github from '../img/github_white.png';
import discord from '../img/discord_white.png';
import twitter from '../img/twitter_white.png';
import telegram from '../img/telegram_white.png';
import { Modal} from "react-bootstrap";
import TwinLogo from '../img/graphics/TWIN_Logo 5.png'


const Footer = ({ children }) => {
  const [disclaimerModalOpen, setDisclaimerModalOpen] = useState(false);
  
  const closeDisclaimerModal = () => {
    setDisclaimerModalOpen(false)
  }
  
  const openDisclaimerModal = () => {
    setDisclaimerModalOpen(true)
  }

  return (
    <div className="row">
       <Modal show={disclaimerModalOpen} onHide={closeDisclaimerModal}>
        <Modal.Header className="border" closeButton>
          <Modal.Title>Legal notice</Modal.Title> 
        </Modal.Header>
        <Modal.Body className="bg-tgrey" style={{
          maxHeight: 'calc(100vh - 210px)',
          overflowY: 'auto'
          }} 
        >
            <div>
              This website provides information and content of general nature about TWIN as a decentralized finance protocol (TWIN Finance protocol) and as a decentralized autonomous organization (TWIN DAO). 
            </div>
            <div>
              The information and the content of this website may be wrong, subject to change or incomplete and may not be updated. You should not rely on this website or on any of the content therein for any kind of investment decision or for legal advice, financial advice, investment advice, or any kind of advice. 
            </div>
            <div>
              You always act at your own risk with respect to the content of this website and the TWIN Finance protocol. In no way are the owners of, or contributors to the TWIN Finance protocol, the TWIN DAO and to this website responsible for the actions, decisions, or other behavior taken or not taken by you in connection with this website or your potential interaction with the TWIN Finance protocol. 
            </div>
            <div>
              Please note that the TWIN Finance protocol is operated by the TWIN DAO and you are solely responsible for compliance with all laws that may apply to you and your use of the TWIN Finance protocol and this website. 
            </div>
            <div>
              Cryptocurrencies and blockchain technologies have been the subject of scrutiny by various regulatory bodies across the globe. 
            </div>
            <div>
              TWIN DAO and its contributors make no representation regarding the application to your use of the TWIN Finance protocol or this website of any laws, including, without limitation, those related to investments, tax, gaming, options, derivatives, or securities. Depending on the jurisdiction, the use of the TWIN Finance protocol may be restricted. 
            </div>
            <div>
              You agree that TWIN DAO and its contributors are not responsible for determining whether or which laws may apply to you and your use of the TWIN Finance protocol and this website. 
            </div>
            <div>
              The TWIN DAO may restrict the use of the TWIN protocol to citizens and residents of certain jurisdictions. Particular restrictions apply for US residents who are strictly prohibited from purchasing, minting, farming or staking crypto assets using the TWIN Finance protocol. 
            </div>
            <div>
              Further, restricted persons should not interact with the TWIN protocol at any time. By interacting with any smart contract of the TWIN protocol you expressly and unconditionally affirm that you are not a resident of the US. 
            </div>
            <div>
              All rights reserved. No guarantees, warranties or whatsoever given. <b>DeFi tools are not toys. Do your own research.</b>
            </div>

          </Modal.Body>
        </Modal>
        
      <div className="footer">
        <div className="copyright">
          <div className="footerLogo">
            <img src={TwinLogo} alt="Twin Logo" width="298"  />
          </div>
          &copy; Copyright 2024 TWIN Finance | All Rights Reserved
        </div>
        

        <div className="footerSocials">
        <div className="footerLinks">
          <div>
            <a href="https://docs.twinfinance.io/" className="">
              Docs
            </a>
          </div>
          <div>
            <a href="#" onClick={openDisclaimerModal} className="">
              Legal
            </a>
          </div>
        </div>
            <a href="https://t.me/+OKQWss_9UDFhMDIy" target="_blank"  rel="noreferrer" className='socials'>
              <div className='iconTelegram'></div>
            </a>
            <a href="https://discord.com/invite/9ZwbkdjmtJ" target="_blank"  rel="noreferrer" className='socials'>
              <div className='iconDiscord'></div>
            </a>
            <a href="https://github.com/TWIN-Protocol/" target="_blank"  rel="noreferrer" className='socials'>
              <div className='iconGithub'></div>
            </a>
            <a href="https://twitter.com/TwinFinance" target="_blank"  rel="noreferrer" className=''>
              <div className='iconTwitter'></div>
            </a>
          </div>
      </div>

      
  </div>
  );
};

export default Footer;