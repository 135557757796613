import {Component} from 'react';
import '../styles/navbar.css';
import TwinLogo from '../img/graphics/TWIN_Logo.png'



class NavbarMobile extends Component{


  
  constructor(props) {
    super(props);
    this.state = {  
      account: '',
      loading: true,
    }
  }

  render(){
    return (
        <div className="navbarContainer">
          <div className="navbarLogo">
            <img src={TwinLogo} alt="Twin Logo" width="139" className="navbarLogo"  />
          </div>
          
          
          
            
            
            
        </div>
    )
  } 
}

export default NavbarMobile;