import {Component} from 'react';
import Mainpage from './components/Mainpage';
import MainpageMobile from './components/MainpageMobile';
import React from "react";
import { Routes, Route } from 'react-router-dom';
//fonts
import "./fonts/PosteramaRegular.ttf"
import "./fonts/OpenSansBold.ttf"
import "./fonts/OpenSansLight.ttf"
import "./fonts/OpenSansRegular.ttf"

const viewportContext = React.createContext({});

const ViewportProvider = ({ children }) => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);
  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <viewportContext.Provider value={{ width, height }}>
      {children}
    </viewportContext.Provider>
  );
};

const useViewport = () => {
  const { width, height } = React.useContext(viewportContext);
  return { width, height };
};



const MyComponent = () => {
  const { width } = useViewport();
  const breakpoint = 700;
  return width < breakpoint ? <MainpageMobile /> : <Mainpage />;
};


class App extends Component{
    
  
  render(){
    
    return (
      <div id="" className="">
          <ViewportProvider>
            <Routes>
              <Route path="/" element={<MyComponent />} />
              <Route path="/small" element={<MainpageMobile />} />
            </Routes>
          </ViewportProvider>
        
        
          
       
    </div>
      
    )
    

  } 
}

export default App;
